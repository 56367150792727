















import { mapGetters } from "vuex";
import { TimeModalSelection as Selection } from '@/app/types/interfaces';
// @ts-ignore
const TimeModal = () => import(/* webpackChunkName: "timemodal" */ "@/views/time-modal/TimeModal.vue");

export default {
  components: { TimeModal },
  props: ["resourceId", "issueId"],
  data() {
    return {
      selection: null,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    closeTimeModal() {
      this.selection = null;
    },
  },
  mounted() {
    const resource:any =
      this.resourceId === "me"
        ? this.$store.getters["Resource/auth/user"]
        : this.$store.getters["Resource/list/resources"].find(
            (resource) => resource.id === parseInt(this.resourceId)
          )
    ;
    const selection:Selection = {
      resourceId: resource.id,
      issueId: this.issueId,
      onlyIssue: true,
    };
    const promises = [
      this.$store.dispatch("Project/list/getList"),
      this.$store.dispatch("Issue/list/getList", { issueIds: [this.issueId] }),
    ];
    Promise.all(promises).then(([_, issues]) => {
      const issue = issues[0];
      selection.projectId = issue.project_id;
      this.selection = selection;
    });
  },
};
